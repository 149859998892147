@use '../../../assets/stylesheets/rpf_design_system/spacing' as *;
@import './../../../assets/stylesheets/component';

$header-logomark-height: 3rem;
$header-logomark-height-large: 4rem;
$header-font-size: var(--font-size-u-1);
$header-font-size-sm: var(--font-size-base);

@mixin nav-state($open: 'false') {
  [data-nav-open='#{ $open }'] & {
    @content;
  }
}

@mixin nav-closed() {
  @include nav-state() {
    @content;
  }
}

@mixin nav-open() {
  @include nav-state('true') {
    @content;
  }
}

.c-site-header {
  background-color: $rpi-color-white;
  box-shadow: 0 1px 0 $rpi-color-black-10;

  color: $rpi-text;
  font-size: $header-font-size;
  position: relative;
  z-index: 1000;

  // Set font sizes for whole nav
  @include rpi-breakpoint(md) {
    font-size: $header-font-size; // Larger when in hamburger
  }

  @include rpi-breakpoint(lg) {
    font-size: $header-font-size-sm; // Smaller to fit all links
  }
  @include rpi-breakpoint(xl) {
    font-size: $header-font-size; // Larger when space available
  }
}

.c-site-header__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $space-0-75 $space-1;

  @include rpi-breakpoint(lg) {
    align-items: center;
    justify-content: space-between;
    padding: $space-1 $space-1;
  }
}

.c-site-header__rpf-link {
  // Ensure all of logo is clickable and is >44px for a11y
  display: inline-block;
  padding: $space-0-25 0;
}

.c-site-header__rpf-logomark {
  height: $header-logomark-height;
  width: auto;

  @include rpi-breakpoint(xs) {
    height: $header-logomark-height-large;
  }
}

.c-site-header__home-link,
.c-site-header__home-link:visited {
  color: $rpi-text;
  flex-shrink: 0;
  font-weight: $rpi-font-weight-medium;
  padding-inline: $space-0-5 $space-1-5;
  text-decoration: none;
  white-space: nowrap;

  @include rpi-breakpoint(lg) {
    padding-inline: $space-1 $space-3;
  }
}

.c-site-header__nav-link,
.c-site-header__nav-link:visited {
  align-items: center;
  color: $rpi-text;
  display: flex;
  font-weight: $rpi-font-weight-medium;
  height: $space-3;
  padding: $space-0-5 $space-1;
  text-decoration: none;
}

.c-site-header__nav-link:hover,
.c-site-header__home-link:hover {
  color: $rpi-color-navy;
}

.c-site-header__nav-link--button {
  background-color: var(--rpi-off-white);
  border-radius: 8px;
}

.c-site-header__language-selector--language {
  align-self: center;
  display: none;
  font-weight: $rpi-font-weight-medium;
  margin-right: 0;
  padding-inline-end: $space-1;
  text-decoration: none;

  @include rpi-breakpoint(xs) {
    display: block;
  }

  @include rpi-breakpoint(lg) {
    padding-inline: $space-1-5 $space-1;
  }
}

.c-site-header__language-selector {
  align-items: center;
  border-inline-start: none;
  display: flex;
  margin-inline-end: $space-0-5;

  @include rpi-breakpoint(lg) {
    border-inline-start: 1px solid $rpi-color-grey-light;
    margin-inline-end: $space-1;
  }
}

.c-site-header__nav-right {
  display: inline-block;
  margin-left: auto;

  @include rpi-breakpoint(lg) {
    display: flex;
  }
}

.c-site-header__skiptocontent-link,
.c-site-header__skiptocontent-link:visited {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  color: $rpi-text;
  display: block;
  font-weight: $rpi-font-weight-medium;
  height: 1px !important;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 $space-1;
  position: absolute !important;
  text-decoration: none;
  white-space: nowrap !important;
  width: 1px !important;
}

.c-site-header__skiptocontent-link:focus {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: inherit !important;
  overflow: visible !important;
  position: static !important;
  white-space: inherit !important;
  width: auto !important;
}

.c-site-header__toggle {
  display: flex;
  justify-content: right;
  order: 100;

  @include rpi-breakpoint(lg) {
    display: none;
  }

  &-button {
    appearance: none;
    background-color: $rpi-text;
    border: none;
    height: $space-3;
    mask-image: url('./images/menu-cross.svg');
    mask-position: 0px 0px;
    mask-size: $space-3;
    transition-duration: 0.1s;
    transition-property: background-color;
    width: $space-3;

    &[aria-pressed='true'] {
      background-color: $rpi-color-red;
      mask-position: 0px 100%;
    }
  }
}

.site-header__nav {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50px;
  transform: translateX(-100%);
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: 2s;
  width: 100%;
  z-index: 10000;

  @include nav-open {
    opacity: 1;
    transform: translateX(0);
  }

  @include rpi-breakpoint(lg) {
    align-self: inherit;
    flex-grow: 1;
    opacity: 1;
    position: static;
    transform: unset;
    transition-property: none;
    width: auto;
  }
}

.site-header-nav {
  background-color: $rpi-color-white;
  margin: 0;
}

.c-site-header-nav__list {
  margin: 0;
  margin: $space-1 $space-1-5;
  padding-block-start: $space-1;
  padding-bottom: $space-1-5;

  @include rpi-breakpoint(lg) {
    align-items: center;
    display: flex;
    gap: $space-0-5;
    justify-content: left;
    line-height: unset;
    margin: 0;
    padding: 0;
    padding-inline-end: $space-0-5;
  }
}

.c-site-header-nav__item {
  align-self: center;
  display: flex;
  opacity: 1;
  transition-duration: 0.15s;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  white-space: nowrap;

  @include rpi-breakpoint(lg) {
    list-style: none;
    margin-bottom: 0;
  }
}

.c-site-header-nav__item:nth-child(3) {
  @include rpi-breakpoint(lg) {
    flex-grow: 1;
  }
}

.c-site-header__nested-links {
  display: none;
  margin-inline-start: $space-1-5;
}

// Right to left styles
.c-i18n-root--rtl {
  .c-site-header-nav__list {
    justify-content: revert;
  }
}

/* The dropdown container */
.c-site-header__dropdown {
  background-color: none;
  border-radius: $space-0-5;
  cursor: pointer;
  float: left;

  font-weight: $rpi-font-weight-medium;
  overflow: hidden;
  user-select: none;
}

.c-site-header__toggle-trigger-icon {
  appearance: none;
  display: inline-block;
  transition: all 150ms ease-in-out;
  vertical-align: middle;
}

/* Dropdown button */
.c-site-header__dropdown .c-site-header__dropdown-button {
  background-color: inherit;
  border: none;
  font-family: inherit;
  line-height: unset;
  margin: 0;
  outline: none;
}

/* Dropdown content (hidden by default) */
.c-site-header__dropdown-content {
  background-color: $rpi-color-white;
  border-radius: $space-0-5;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: none;
  margin-top: $space-0-5;
  padding: $space-0-5;
  position: absolute;
  z-index: 1;
}

/* Links inside the dropdown */
.c-site-header__dropdown-content a {
  color: black;
  display: block;
  float: none;
  padding: $space-0-75 $space-1;
  text-align: left;
  text-decoration: none;
}

.c-site-header__dropdown-background {
  align-items: center;
  background-color: none;
  display: flex;
  gap: $space-0-5;
  height: $space-3;
  padding: $space-0-75 $space-1;

  @include rpi-breakpoint(lg) {
    background-color: none;
    border-radius: $space-0-5;
  }
}

.c-site-header__dropdown-background:hover {
  color: $rpi-color-navy;

  .c-site-header__toggle-trigger-icon {
    path {
      fill: $rpi-color-navy;
    }
  }
}

.c-site-header__dropdown-button:checked ~ {
  .c-site-header__dropdown-content {
    @include rpi-breakpoint(lg) {
      display: block;
    }
  }

  .c-site-header__dropdown-background {
    @include rpi-breakpoint(lg) {
      background-color: $rpi-color-grey-x-light;
    }

    .c-site-header__toggle-trigger-icon {
      transform: rotate(180deg);
    }
  }

  .c-site-header__nested-links {
    display: block;

    @include rpi-breakpoint(lg) {
      display: none;
    }
  }

  .c-filter__dropdowns {
    display: block;
  }
}

.c-site-header__mobile-nav-only {
  display: inline-block;

  @include rpi-breakpoint(sm) {
    display: none;
  }
}

.c-site-desktop-nav-only {
  display: none;

  @include rpi-breakpoint(sm) {
    display: inline-block;
  }
}
