@import './../../../assets/stylesheets/component';

$rpi-color-footer-background: #edeef1;
$rpi-color-footer-grey: #666;
$rpi-color-footer-textarea-border: #ccc;
$rpi-color-footer-button-disabled-bg: #eee;

.c-project-footer {
  background-color: $rpi-color-footer-background;
  margin: 0 auto;
  padding: rpi-spacing-unit(4) rpi-spacing-unit(2);
  text-align: center;

  p {
    color: $rpi-color-footer-grey;
    font-size: 14px;
    margin-bottom: 1em;

    &:last-child {
      margin: 0;
    }
  }
}

.c-project-footer__link,
.c-project-footer__link:hover,
.c-project-footer__link:visited {
  color: $rpi-color-footer-grey;
}

.c-project-footer__feedback-prompt,
.c-project-footer__feedback-submit {
  @include rpi-type($rpi-font-size-base, 1);

  background-color: transparent;
  border-color: var(--rpi-grey-70);
  border-radius: 5px;
  border-style: solid;
  border-width: 3px;
  color: var(--rpi-grey-70);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font-weight-bold);
  line-height: rpi-spacing-unit(5);
  margin-bottom: rpi-spacing-unit(3);
  padding: 0 rpi-spacing-unit(3);
  text-decoration: none;
  transition: all 150ms ease-in-out;

  &:hover {
    background-color: $rpi-color-white;
    text-decoration: none;
  }

  &:visited {
    color: $rpi-color-grey-mid;
  }

  &[disabled],
  &:hover[disabled] {
    background-color: $rpi-color-footer-button-disabled-bg;
    border-color: $rpi-color-footer-button-disabled-bg;
    color: $rpi-color-grey-mid;
    cursor: default;
    pointer-events: none;
  }
}

.c-project-footer__feedback-form {
  margin: 0 auto rpi-spacing-unit(3);
  max-width: 350px;
  text-align: center;
  width: 100%;
}

.c-project-footer__feedback-textarea {
  border-color: $rpi-color-footer-textarea-border;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  display: block;
  font-family: $rpi-font-family-sans-serif;
  font-size: $rpi-font-size-base;
  height: rpi-spacing-unit(15);
  margin-bottom: rpi-spacing-unit(2);
  padding: rpi-spacing-unit();
  text-align: left;
  width: 100%;

  &::placeholder {
    color: $rpi-color-grey-light;
  }

  &:focus {
    border-color: $rpi-color-grey-mid;
    outline-offset: 1px;
  }

  &[disabled] {
    border-color: $rpi-color-footer-textarea-border;
    color: $rpi-color-grey-light;
  }
}

.c-project-footer__feedback-thanks {
  @include rpi-typog('h1');

  font-weight: $rpi-font-weight-bold;
  margin-bottom: rpi-spacing-unit(2);
  text-transform: uppercase;
}

.c-project-footer__feedback-form--hidden,
.c-project-footer__feedback-prompt--hidden,
.c-project-footer__feedback-thanks--hidden {
  display: none;
}

.c-project-footer-policy {
  column-gap: 30px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}
