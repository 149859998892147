@import url('https://fonts.googleapis.com/css?family=Rubik:300,500,700');
@import 'node_modules/raspberry-pi-bits/src/styles/react-main';
@import 'node_modules/raspberry-pi-bits/src/components/PrintPageBreak/PrintPageBreak';

body {
  min-width: 275px;
  width: auto !important;
  width: 275px;
}

#root {
  container-name: root;
  container-type: normal;
  min-height: 100dvh;
}
