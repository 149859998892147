/* stylelint-disable selector-pseudo-class-no-unknown */
:export {
  brand-rpi-color-brand-cc-green: #009b3a;
  palette-rpi-color-blue: #41a6c4;
  palette-rpi-color-blue-light: #ecf6f9;
  palette-rpi-color-green: #53cf63;
  palette-rpi-color-green-dark: #4ab858;
  palette-rpi-color-navy: #004881;
  palette-rpi-color-navy-blue: #2253E7;
  palette-rpi-color-orange: #f68e3b;
  palette-rpi-color-orange-light: #fef3eb;
  palette-rpi-color-pink: #ffdddd;
  palette-rpi-color-red: #f3524f;
  palette-rpi-color-turquoise: #0faeb0;
  palette-rpi-color-violet: #b85ca8;
  palette-rpi-color-yellow: #ffb12a;
  greyScale-rpi-color-black: #000;
  greyScale-rpi-color-black-10: rgba(0, 0, 0, 0.1);
  greyScale-rpi-color-black-15: rgba(0, 0, 0, 0.15);
  greyScale-rpi-color-black-25: rgba(0, 0, 0, 0.25);
  greyScale-rpi-color-black-30: rgba(0, 0, 0, 0.3);
  greyScale-rpi-color-black-40: rgba(0, 0, 0, 0.4);
  greyScale-rpi-color-grey-dark: #535353;
  greyScale-rpi-color-grey-light: #b8b8b8;
  greyScale-rpi-color-grey-mid: #999;
  greyScale-rpi-color-grey-x-dark: #222;
  greyScale-rpi-color-grey-x-light: #f5f6f9;
  greyScale-rpi-color-grey-y-light: #ccc;
  greyScale-rpi-color-white: #fff;
  greyScale-rpi-color-white-20: rgba(255, 255, 255, 0.2);
  greyScale-rpi-color-white-50: rgba(255, 255, 255, 0.5);
  greyScale-rpi-color-white-70: rgba(255, 255, 255, 0.7);
}

/* stylelint-enable selector-pseudo-class-no-unknown */
/**
 * The base value the scale starts at.
 *
 * Number
 */
/**
 * The ratio the scale is built on
 *
 * Unitless Number
 */
/**
 * Length of scale ( right part of the decimal
 * point ) ms will be rounded to.
 *
 * Unitless Number
 */
/* stylelint-disable */
/**
 * Based on a scale with a base of 1rem and a ratio of 1.2
 * https://www.modularscale.com/?1&rem&1.2
 * Each step up:   (previous-step) * 1.2
 * Each step down: (previous-step) / 1.2
 */
:root,
:host {
  --font-size-base: 1.6rem;
  --font-size-ratio: 1.2;
  --font-size-u-8: 6.879707136rem;
  --font-size-u-7: 5.73308928rem;
  --font-size-u-6: 4.7775744rem;
  --font-size-u-5: 3.981312rem;
  --font-size-u-4: 3.31776rem;
  --font-size-u-3: 2.7648rem;
  --font-size-u-2: 2.304rem;
  --font-size-u-1: 1.92rem;
  --font-size-d-1: 1.3333333333rem;
  --font-size-d-2: 1.1111111111rem;
  --font-size-d-3: 0.9259259259rem;
  --font-size-root-clamp-ideal: 3vmin;
  --font-size-root-clamp-min: 1em;
  --font-size-root-clamp-max: 1.25em;
  --font-size-root-clamp-fallback: 1em;
}

:root,
:host {
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}

:root,
:host {
  --spacing-multiplier: 0.8;
  --spacing-0: 0;
  --spacing-1: calc(1rem * var(--spacing-multiplier));
  --spacing-2: calc(2rem * var(--spacing-multiplier));
  --spacing-3: calc(3rem * var(--spacing-multiplier));
  --spacing-4: calc(4rem * var(--spacing-multiplier));
  --spacing-5: calc(5rem * var(--spacing-multiplier));
  --spacing-6: calc(6rem * var(--spacing-multiplier));
  --spacing-7: calc(7rem * var(--spacing-multiplier));
  --spacing-8: calc(8rem * var(--spacing-multiplier));
  --spacing-9: calc(9rem * var(--spacing-multiplier));
  --spacing-10: calc(10rem * var(--spacing-multiplier));
}

.u-visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.shadow {
  align-items: center;
  background-color: #FF1493;
  display: flex;
  height: 50vh;
  justify-content: center;
  margin-bottom: 2rem;
  width: 50vw;
}

.rpf-shadow-bottom {
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.4);
}

.rpf-shadow-centre {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}

.rpf-shadow-top {
  box-shadow: 0px -12px 24px 0px rgba(0, 0, 0, 0.04);
}

:root,
:host {
  --rpi-transparent: rgba(0, 0, 0, 0);
  --rpi-white: #fff;
  --rpi-black: #000;
  --rpi-red: #f3524f;
  --rpi-red-shade-20: #c2423f;
  --rpi-red-tint-25: #f67d7b;
  --rpi-red-tint-50: #f9a9a7;
  --rpi-red-tint-75: #fcd4d3;
  --rpi-red-tint-90: #feeeed;
  --rpi-blue: #008ff0;
  --rpi-blue-shade-20: #0072c0;
  --rpi-blue-tint-25: #40abf4;
  --rpi-blue-tint-50: #80c7f8;
  --rpi-blue-tint-75: #bfe3fb;
  --rpi-blue-tint-90: #e6f4fe;
  --rpi-navy: #232e9b;
  --rpi-navy-shade-20: #1c257c;
  --rpi-navy-tint-25: #5a62b4;
  --rpi-navy-tint-50: #9197cd;
  --rpi-navy-tint-75: #c8cbe6;
  --rpi-navy-tint-90: #e9eaf5;
  --rpi-orange: #fe8e15;
  --rpi-orange-shade-20: #af620e;
  --rpi-orange-tint-25: #feaa50;
  --rpi-orange-tint-50: #ffc78a;
  --rpi-orange-tint-75: #ffe3c5;
  --rpi-orange-tint-90: #fff4e8;
  --rpi-yellow: #ffdc00;
  --rpi-yellow-shade-20: #877500;
  --rpi-yellow-tint-25: #ffe540;
  --rpi-yellow-tint-50: #ffee80;
  --rpi-yellow-tint-75: #fff6bf;
  --rpi-yellow-tint-90: #fffce6;
  --rpi-purple: #8744aa;
  --rpi-purple-shade-20: #6c3688;
  --rpi-purple-tint-25: #a573bf;
  --rpi-purple-tint-50: #c3a2d5;
  --rpi-purple-tint-75: #e1d0ea;
  --rpi-purple-tint-90: #f3ecf7;
  --rpi-green: #46af4b;
  --rpi-green-shade-20: #358539;
  --rpi-green-tint-25: #74c378;
  --rpi-green-tint-50: #a3d7a5;
  --rpi-green-tint-75: #d1ebd2;
  --rpi-green-tint-90: #edf7ed;
  --rpi-teal: #14bcac;
  --rpi-teal-shade-20: #0e857a;
  --rpi-teal-tint-25: #4fcdc1;
  --rpi-teal-tint-50: #8aded6;
  --rpi-teal-tint-75: #c4eeea;
  --rpi-teal-tint-90: #e8f8f7;
  --rpi-black: #000;
  --rpi-white: #fff;
  --rpi-grey-5: #f2f2f2;
  --rpi-grey-15: #d9d9d9;
  --rpi-grey-40: #999999;
  --rpi-grey-70: #4d4d4d;
  --rpi-off-white: #f5f6f9;
  --rpi-text: #212121;
  --rpi-black-alpha-10: $rpi-black-alpha-10;
  --rpi-black-alpha-15: $rpi-black-alpha-15;
  --rpi-black-alpha-25: $rpi-black-alpha-25;
  --rpi-black-alpha-30: $rpi-black-alpha-30;
  --rpi-black-alpha-40: $rpi-black-alpha-40;
  --rpi-brand-raspberry: #cd2355;
  --rpi-brand-leaf: #46af4b;
  --rpi-brand-black: #1d1d1b;
}

/* stylelint-disable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:export {
  red-rpi-red: #f3524f;
  red-rpi-red-shade-20: #c2423f;
  red-rpi-red-tint-25: #f67d7b;
  red-rpi-red-tint-50: #f9a9a7;
  red-rpi-red-tint-75: #fcd4d3;
  red-rpi-red-tint-90: #feeeed;
  blue-rpi-blue: #008ff0;
  blue-rpi-blue-shade-20: #0072c0;
  blue-rpi-blue-tint-25: #40abf4;
  blue-rpi-blue-tint-50: #80c7f8;
  blue-rpi-blue-tint-75: #bfe3fb;
  blue-rpi-blue-tint-90: #e6f4fe;
  navy-rpi-navy: #232e9b;
  navy-rpi-navy-shade-20: #1c257c;
  navy-rpi-navy-tint-25: #5a62b4;
  navy-rpi-navy-tint-50: #9197cd;
  navy-rpi-navy-tint-75: #c8cbe6;
  navy-rpi-navy-tint-90: #e9eaf5;
  orange-rpi-orange: #fe8e15;
  orange-rpi-orange-shade-20: #af620e;
  orange-rpi-orange-tint-25: #feaa50;
  orange-rpi-orange-tint-50: #ffc78a;
  orange-rpi-orange-tint-75: #ffe3c5;
  orange-rpi-orange-tint-90: #fff4e8;
  yellow-rpi-yellow: #ffdc00;
  yellow-rpi-yellow-shade-20: #877500;
  yellow-rpi-yellow-tint-25: #ffe540;
  yellow-rpi-yellow-tint-50: #ffee80;
  yellow-rpi-yellow-tint-75: #fff6bf;
  yellow-rpi-yellow-tint-90: #fffce6;
  purple-rpi-purple: #8744aa;
  purple-rpi-purple-shade-20: #6c3688;
  purple-rpi-purple-tint-25: #a573bf;
  purple-rpi-purple-tint-50: #c3a2d5;
  purple-rpi-purple-tint-75: #e1d0ea;
  purple-rpi-purple-tint-90: #f3ecf7;
  violet-rpi-violet: #8744aa;
  violet-rpi-violet-shade-20: #6c3688;
  violet-rpi-violet-tint-25: #a573bf;
  violet-rpi-violet-tint-50: #c3a2d5;
  violet-rpi-violet-tint-75: #e1d0ea;
  violet-rpi-violet-tint-90: #f3ecf7;
  green-rpi-green: #46af4b;
  green-rpi-green-shade-20: #358539;
  green-rpi-green-tint-25: #74c378;
  green-rpi-green-tint-50: #a3d7a5;
  green-rpi-green-tint-75: #d1ebd2;
  green-rpi-green-tint-90: #edf7ed;
  teal-rpi-teal: #14bcac;
  teal-rpi-teal-shade-20: #0e857a;
  teal-rpi-teal-tint-25: #4fcdc1;
  teal-rpi-teal-tint-50: #8aded6;
  teal-rpi-teal-tint-75: #c4eeea;
  teal-rpi-teal-tint-90: #e8f8f7;
  grey-rpi-transparent: rgba(0, 0, 0, 0);
  grey-rpi-black: #000;
  grey-rpi-grey-15: #d9d9d9;
  grey-rpi-grey-40: #999999;
  grey-rpi-grey-5: #f2f2f2;
  grey-rpi-grey-70: #4d4d4d;
  grey-rpi-off-white: #f5f6f9;
  grey-rpi-text: #212121;
  grey-rpi-white: #fff;
  grey-rpi-black-alpha-10: rgba(0, 0, 0, 0.1);
  grey-rpi-black-alpha-15: rgba(0, 0, 0, 0.15);
  grey-rpi-black-alpha-25: rgba(0, 0, 0, 0.25);
  grey-rpi-black-alpha-30: rgba(0, 0, 0, 0.3);
  grey-rpi-black-alpha-40: rgba(0, 0, 0, 0.4);
  brand-rpi-brand-black: #1d1d1b;
  brand-rpi-brand-leaf: #46af4b;
  brand-rpi-brand-raspberry: #cd2355;
}

/* stylelint-enable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
[data-sauce-theme=projects-ui] {
  --font-family-heading: "Roboto", sans-serif;
  --font-family-monospace: "Space Mono", monospace;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-serif: "Roboto", serif;
}

:root,
:host {
  --rpi-transparent: rgba(0, 0, 0, 0);
  --rpi-white: #fff;
  --rpi-black: #000;
  --rpi-red: #f3524f;
  --rpi-red-shade-20: #c2423f;
  --rpi-red-tint-25: #f67d7b;
  --rpi-red-tint-50: #f9a9a7;
  --rpi-red-tint-75: #fcd4d3;
  --rpi-red-tint-90: #feeeed;
  --rpi-blue: #008ff0;
  --rpi-blue-shade-20: #0072c0;
  --rpi-blue-tint-25: #40abf4;
  --rpi-blue-tint-50: #80c7f8;
  --rpi-blue-tint-75: #bfe3fb;
  --rpi-blue-tint-90: #e6f4fe;
  --rpi-navy: #232e9b;
  --rpi-navy-shade-20: #1c257c;
  --rpi-navy-tint-25: #5a62b4;
  --rpi-navy-tint-50: #9197cd;
  --rpi-navy-tint-75: #c8cbe6;
  --rpi-navy-tint-90: #e9eaf5;
  --rpi-orange: #fe8e15;
  --rpi-orange-shade-20: #af620e;
  --rpi-orange-tint-25: #feaa50;
  --rpi-orange-tint-50: #ffc78a;
  --rpi-orange-tint-75: #ffe3c5;
  --rpi-orange-tint-90: #fff4e8;
  --rpi-yellow: #ffdc00;
  --rpi-yellow-shade-20: #877500;
  --rpi-yellow-tint-25: #ffe540;
  --rpi-yellow-tint-50: #ffee80;
  --rpi-yellow-tint-75: #fff6bf;
  --rpi-yellow-tint-90: #fffce6;
  --rpi-purple: #8744aa;
  --rpi-purple-shade-20: #6c3688;
  --rpi-purple-tint-25: #a573bf;
  --rpi-purple-tint-50: #c3a2d5;
  --rpi-purple-tint-75: #e1d0ea;
  --rpi-purple-tint-90: #f3ecf7;
  --rpi-green: #46af4b;
  --rpi-green-shade-20: #358539;
  --rpi-green-tint-25: #74c378;
  --rpi-green-tint-50: #a3d7a5;
  --rpi-green-tint-75: #d1ebd2;
  --rpi-green-tint-90: #edf7ed;
  --rpi-teal: #14bcac;
  --rpi-teal-shade-20: #0e857a;
  --rpi-teal-tint-25: #4fcdc1;
  --rpi-teal-tint-50: #8aded6;
  --rpi-teal-tint-75: #c4eeea;
  --rpi-teal-tint-90: #e8f8f7;
  --rpi-black: #000;
  --rpi-white: #fff;
  --rpi-grey-5: #f2f2f2;
  --rpi-grey-15: #d9d9d9;
  --rpi-grey-40: #999999;
  --rpi-grey-70: #4d4d4d;
  --rpi-off-white: #f5f6f9;
  --rpi-text: #212121;
  --rpi-black-alpha-10: $rpi-black-alpha-10;
  --rpi-black-alpha-15: $rpi-black-alpha-15;
  --rpi-black-alpha-25: $rpi-black-alpha-25;
  --rpi-black-alpha-30: $rpi-black-alpha-30;
  --rpi-black-alpha-40: $rpi-black-alpha-40;
  --rpi-brand-raspberry: #cd2355;
  --rpi-brand-leaf: #46af4b;
  --rpi-brand-black: #1d1d1b;
}

/* stylelint-disable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:export {
  red-rpi-red: #f3524f;
  red-rpi-red-shade-20: #c2423f;
  red-rpi-red-tint-25: #f67d7b;
  red-rpi-red-tint-50: #f9a9a7;
  red-rpi-red-tint-75: #fcd4d3;
  red-rpi-red-tint-90: #feeeed;
  blue-rpi-blue: #008ff0;
  blue-rpi-blue-shade-20: #0072c0;
  blue-rpi-blue-tint-25: #40abf4;
  blue-rpi-blue-tint-50: #80c7f8;
  blue-rpi-blue-tint-75: #bfe3fb;
  blue-rpi-blue-tint-90: #e6f4fe;
  navy-rpi-navy: #232e9b;
  navy-rpi-navy-shade-20: #1c257c;
  navy-rpi-navy-tint-25: #5a62b4;
  navy-rpi-navy-tint-50: #9197cd;
  navy-rpi-navy-tint-75: #c8cbe6;
  navy-rpi-navy-tint-90: #e9eaf5;
  orange-rpi-orange: #fe8e15;
  orange-rpi-orange-shade-20: #af620e;
  orange-rpi-orange-tint-25: #feaa50;
  orange-rpi-orange-tint-50: #ffc78a;
  orange-rpi-orange-tint-75: #ffe3c5;
  orange-rpi-orange-tint-90: #fff4e8;
  yellow-rpi-yellow: #ffdc00;
  yellow-rpi-yellow-shade-20: #877500;
  yellow-rpi-yellow-tint-25: #ffe540;
  yellow-rpi-yellow-tint-50: #ffee80;
  yellow-rpi-yellow-tint-75: #fff6bf;
  yellow-rpi-yellow-tint-90: #fffce6;
  purple-rpi-purple: #8744aa;
  purple-rpi-purple-shade-20: #6c3688;
  purple-rpi-purple-tint-25: #a573bf;
  purple-rpi-purple-tint-50: #c3a2d5;
  purple-rpi-purple-tint-75: #e1d0ea;
  purple-rpi-purple-tint-90: #f3ecf7;
  violet-rpi-violet: #8744aa;
  violet-rpi-violet-shade-20: #6c3688;
  violet-rpi-violet-tint-25: #a573bf;
  violet-rpi-violet-tint-50: #c3a2d5;
  violet-rpi-violet-tint-75: #e1d0ea;
  violet-rpi-violet-tint-90: #f3ecf7;
  green-rpi-green: #46af4b;
  green-rpi-green-shade-20: #358539;
  green-rpi-green-tint-25: #74c378;
  green-rpi-green-tint-50: #a3d7a5;
  green-rpi-green-tint-75: #d1ebd2;
  green-rpi-green-tint-90: #edf7ed;
  teal-rpi-teal: #14bcac;
  teal-rpi-teal-shade-20: #0e857a;
  teal-rpi-teal-tint-25: #4fcdc1;
  teal-rpi-teal-tint-50: #8aded6;
  teal-rpi-teal-tint-75: #c4eeea;
  teal-rpi-teal-tint-90: #e8f8f7;
  grey-rpi-transparent: rgba(0, 0, 0, 0);
  grey-rpi-black: #000;
  grey-rpi-grey-15: #d9d9d9;
  grey-rpi-grey-40: #999999;
  grey-rpi-grey-5: #f2f2f2;
  grey-rpi-grey-70: #4d4d4d;
  grey-rpi-off-white: #f5f6f9;
  grey-rpi-text: #212121;
  grey-rpi-white: #fff;
  grey-rpi-black-alpha-10: rgba(0, 0, 0, 0.1);
  grey-rpi-black-alpha-15: rgba(0, 0, 0, 0.15);
  grey-rpi-black-alpha-25: rgba(0, 0, 0, 0.25);
  grey-rpi-black-alpha-30: rgba(0, 0, 0, 0.3);
  grey-rpi-black-alpha-40: rgba(0, 0, 0, 0.4);
  brand-rpi-brand-black: #1d1d1b;
  brand-rpi-brand-leaf: #46af4b;
  brand-rpi-brand-raspberry: #cd2355;
}

/* stylelint-enable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:root,
:host {
  --theme-100: var(--rpf-teal-100);
  --theme-200: var(--rpf-teal-200);
  --theme-300: var(--rpf-teal-800);
  --theme-400: var(--rpf-teal-900);
  --rpf-squiggle-color: var(--theme-300);
}

.computer {
  background-image: url("./computer.png");
  height: 74px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 74px;
}

.screen {
  background-image: url("./screen.gif");
  height: 40px;
  left: 7px;
  position: absolute;
  top: 8px;
  width: 60px;
}

